import { createAction, createFeatureSelector, createSelector, props } from '@ngrx/store';
import { ISite } from '../../project/sites-and-study-team/study-and-study-team-manage/site.interface';
import { CurrentSiteManage, SiteState } from './site.state';

const PREFIX = '[SITE]';

export enum ActionTypes {
  LOAD_SITES_LIST = `${PREFIX} LOAD_SITES_LIST`,
  CREATE_SITE = `${PREFIX} CREATE_SITE`,
  UPDATE_SITE = `${PREFIX} UPDATE_SITE`,
  POPULATE_SITES_LIST = `${PREFIX} POPULATE_SITES_LIST`,
  SEARCH_SITE_BY_ID = `${PREFIX} SEARCH_SITE_BY_ID`,
  POPULATE_CURRENT_SITE = `${PREFIX} POPULATE_CURRENT_SITE`,
  POPULATE_CURRENT_SITE_SNAPSHOT = `${PREFIX} POPULATE_CURRENT_SITE_SNAPSHOT`,
  PENDING_CREATE_SITE = `${PREFIX} PENDING_CREATE_SITE`,
  DISPATCHED_CREATE_SITE = `${PREFIX} DISPATCHED_CREATE_SITE`,
  SUCCESS_CREATE_SITE = `${PREFIX} SUCCESS_CREATE_SITE`,
  UPDATE_SITE_FIELD = `${PREFIX} UPDATE_SITE_FIELD`,
  UPDATE_SITE_FIELDS = `${PREFIX} UPDATE_SITE_FIELDS`,
  RESET_CURRENT_SITE = `${PREFIX} RESET_CURRENT_SITE`,

}

// export const getRecordForms = createAction(ActionTypes.GET_RECORD_FORMS, props<{
//   projectId: number,
//   datasetId: number
// }>());
//
// export const populateRecordForms = createAction(ActionTypes.POPULATE_RECORD_FORMS, props<{
//   recordForms: RecordFormsModel[]
// }>());
//
export const loadSitesList = createAction(ActionTypes.LOAD_SITES_LIST);
//
// export const updateProjectList = createAction(ActionTypes.UPDATEPROJECTLIST, props<{ projectList: ProjectModel[] }>());
//
// export const deleteProject = createAction(ActionTypes.DELETEPROJECT, props<{ id: number }>());
//
// export const removeProject = createAction(ActionTypes.REMOVEPROJECT, props<{ id: number }>());
//
// export const resetCurrentProject = createAction(ActionTypes.RESET_CURRENT_PROJECT);
//
// export const refreshCurrentProject = createAction(ActionTypes.REFRESH_CURRENT_PROJECT);
//
export const populateCurrentSite = createAction(ActionTypes.POPULATE_CURRENT_SITE, props<{
  site: ISite
}>());
export const populateCurrentSiteSnapshot = createAction(ActionTypes.POPULATE_CURRENT_SITE_SNAPSHOT, props<{
  site: ISite
}>());
//
//
export const searchSiteById = createAction(ActionTypes.SEARCH_SITE_BY_ID, props<{ id: number }>());
export const createSite = createAction(ActionTypes.CREATE_SITE, props<{ site: Partial<ISite> }>());
//
export const updateSite = createAction(ActionTypes.UPDATE_SITE, props<{ site: ISite }>());
export const resetCurrentSite = createAction(ActionTypes.RESET_CURRENT_SITE);
export const pendingCreateSite = createAction(ActionTypes.PENDING_CREATE_SITE, props<{ pending: boolean }>());

export const dispatchedCreateSite = createAction(ActionTypes.DISPATCHED_CREATE_SITE, props<{
  dispatched: boolean
}>());

export const successCreateSite = createAction(ActionTypes.SUCCESS_CREATE_SITE, props<{ success: boolean }>());
//
// export const updateProjectInList = createAction(ActionTypes.UPDATE_PROJECT_IN_LIST, props<{ project: ProjectModel }>());
//
// export const addProject = createAction(ActionTypes.ADD_PROJECT, props<{ project: ProjectModel }>());
//
export const updateSiteField = createAction(ActionTypes.UPDATE_SITE_FIELD, props<{
  field: string,
  value: string | number | any
}>());

export const updateSiteFields = createAction(ActionTypes.UPDATE_SITE_FIELDS, props<{
  partialSite: Partial<ISite>
}>());
export const populateSitesList = createAction(ActionTypes.POPULATE_SITES_LIST, props<{
  sites: Partial<ISite[]>
}>());
// export const checkCurrentProjectForPendingChanges = createAction(
//   ActionTypes.CHECK_CURRENT_PROJECT_FOR_PENDING_CHANGES,
//   props<{ hasChanges: boolean }>()
// );
//
export const selectSiteFeature = createFeatureSelector<SiteState>('site');
export const selectCurrentSite = createSelector(
  selectSiteFeature,
  (state: SiteState) => state.current
);
export const selectCurrentSelectedSite = createSelector(
  selectCurrentSite,
  (state: CurrentSiteManage) => state.site
);

export const selectSitesList = createSelector(
  selectSiteFeature,
  (state: SiteState) => state.list
);

// export const checkCurrentProjectLockedState = createSelector(
//   selectCurrentProject,
//   (state: CurrentProjectManage) => state.isLocked
// );
// export const selectCurrentProjectManageProject = createSelector(
//   selectCurrentProject,
//   (state: CurrentProjectManage) => state.project
// );
//
// export const selectCurrentProjectManageProjectSnapshot = createSelector(
//   selectCurrentProject,
//   (state: CurrentProjectManage) => state.projectSnapshot
// );
//
